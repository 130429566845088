<template>
  <b-card>
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-form></b-form>
        <b-row>
          <!-- Max Manual Amount  -->
          <b-col cols="12">
            <b-form-group id="input-max_manual_amount">
              <label for="name-max_manual_amount">Max Manual Amount: <span class="text-danger">*</span></label>
              <b-form-input
                name="max_manual_amount"
                v-model="editedItem.max_manual_amount"
                type="number"
                aria-describedby="input-max_manual_amount"
                data-vv-as="max_manual_amount"
                :class="{
                  input: true,
                  'is-danger': errors.has('max_manual_amount')
                }"
                v-validate="{ required: true, min: 1 }"
              ></b-form-input>
              <span v-show="errors.has('max_manual_amount')" class="is-danger-text position-relative">{{
                errors.first('max_manual_amount')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Min Manual Amount  -->
          <b-col cols="12">
            <b-form-group id="input-min_manual_amount">
              <label for="name-min_manual_amount">Min Manual Amount: <span class="text-danger">*</span></label>
              <b-form-input
                name="min_manual_amount"
                v-model="editedItem.min_manual_amount"
                type="number"
                aria-describedby="input-min_manual_amount"
                data-vv-as="min_manual_amount"
                :class="{
                  input: true,
                  'is-danger': errors.has('min_manual_amount')
                }"
                v-validate="{ required: true, min: 1 }"
              ></b-form-input>
              <span v-show="errors.has('min_manual_amount')" class="is-danger-text position-relative">{{
                errors.first('min_manual_amount')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="12">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">{{
                errors.first('Status')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Reward Rule List</h4>
      </b-col>
      <b-col cols="4">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="8">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(max_manual_amount)="item">
        {{ formatPrice(item.item.max_manual_amount) }}
      </template>
      <template v-slot:cell(min_manual_amount)="item">
        {{ formatPrice(item.item.min_manual_amount) }}
      </template>

      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>

      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'Reward Rule List',
  components: {},
  data() {
    return {
      isCreated: true,
      search: '',
      isBusy: false,
      filter_name: '',
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Max_Manual_Mmount', key: 'max_manual_amount' },
        { text: 'Min_manual_amount', key: 'min_manual_amount' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      // allGameAdsList: [],
      selectedRewardRule: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        max_manual_amount: '',
        min_manual_amount: ''
      },
      dialog: false,
      editedIndex: -1
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('max_manual_amount') ||
        !this.editedItem.min_manual_amount ||
        this.errors.has('min_manual_amount') ||
        this.errors.has('Status') ||
        this.filter_status === null
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Reward Rules' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsRewardRule = ApiService.get(
        'reward-rules',
        `?status=${this.filter_status == null ? '' : this.filter_status}&offset=${offset}&limit=${limit}`
      );
      axios
        .all([adsRewardRule])
        .then(
          axios.spread((...response) => {
            const adsRewardRule = response[0];
            this.items = adsRewardRule.data.data.rewardRuleList.map((ads) => {
              return {
                ...ads
              };
            });
            this.rows = adsRewardRule.data.data.total;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.errors.push(errors);
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.isCreated = true;
      this.dialog = true;
      this.modalTitle = 'Add Reward Rule';
    },
    editItem: function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Reward Rule';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedRewardRule = item.id;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`reward-rules/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Product deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        max_manual_amount: '',
        min_manual_amount: ''
      };
      this.editedIndex = -1;
      this.selectedRewardRule = null;
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      this.editedItem.id = this.selectedRewardRule;
      let rewardRuleData = {
        max_manual_amount: +parseFloat(this.editedItem.max_manual_amount).toFixed(2),
        min_manual_amount: +parseFloat(this.editedItem.min_manual_amount).toFixed(2),
        status: parseInt(this.filter_status)
      };

      if (this.editedIndex > -1) {
        // Update product
        axios({
          method: 'PUT',
          url: `reward-rules/${this.items[this.editedIndex].id}`,
          data: rewardRuleData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Product updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      } else {
        // Add product
        axios({
          method: 'POST',
          url: 'reward-rules',
          data: rewardRuleData,
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data
              });
              this.close();
              this.$bvToast.toast('Product added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    updateGame() {}
  }
};
</script>
